import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'

import Img from "gatsby-image"

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Layout from '../components/Layout'
import Share from '../components/Share'
import Seo from '../components/Seo'
import {default as Helmet, baseURL } from '../components/Helmet'
import Bio from '../components/Bio'
import TitleDatePhoto from '../components/TitleDatePhoto'

import { themeStyles } from '../utils/theme'
import { rhythm } from '../utils/typography'
import colors from '../utils/colors'

const styles = {
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    '& a': {
      backgroundColor: colors.blue,
      borderRadius: '5%',
      color: colors.white,
      fontSize: rhythm(0.75),
      fontWeight: 'bold',
      margin: rhythm(0.5),
      marginTop: 0,
      padding: rhythm(0.5),
      textDecoration: 'none',
      '& svg': {
        fontWeight: 'normal',
        verticalAlign: 'middle',
        marginBottom: '2px',
      }
    },
  },
}

class PostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.markdownRemark')

    const { pageContext, data } = get(this, 'props')
    const { previousPagePath, nextPagePath, previousItem, nextItem } = pageContext

    const slug = get(post, 'fields.slug')
    const title = get(post, 'frontmatter.title')
    const date = get(post, 'frontmatter.date')
    const excerpt = get(post, 'excerpt')
    const titleImageResponsive = get(post, 'frontmatter.featuredImage.childImageSharp.fluid')
    const thumbnailImage = get(post, 'frontmatter.featuredImage.childImageSharp.fluid.src')
    const fullThumbnailImageURL = baseURL + thumbnailImage

    return (
      <Layout location={this.props.location}>
        <Seo
          title={title}
          description={excerpt}
        />
        <Helmet
          description={excerpt}
          image={fullThumbnailImageURL}
          title={title}
          type="article"
          path={slug}
        />
        <div css={themeStyles.textPadding}>
          {titleImageResponsive && <TitleDatePhoto title={title} date={date} imageResponsive={titleImageResponsive} />}
          <div css={{marginBottom: rhythm(1)}}/>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div css={styles.pagination}>
            {previousPagePath ? (
              <Link to={previousPagePath}>
              <FaChevronLeft /> Newer Post
              </Link>
            ) : null}
            {nextPagePath ? (
              <Link to={nextPagePath}>
                Older Post <FaChevronRight />
              </Link>
            ) : null}
        </div>
        </div>
        <Bio />
        <Share slug={slug} title={title} excerpt={excerpt} />
    </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($pageId: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(id: { eq: $pageId }) {
      id
      excerpt(pruneLength: 200)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

  }
`
